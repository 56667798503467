import { computed } from "vue";
import { useStore } from "@/store";

export const useSimsStore = () => {
  const store = useStore();
  const orgTagsForSims = computed(() => store.getters["sims/sortedTags"]);
  const orgCustomFields = computed(() => store.getters["sims/customFields"]);
  const orgCustomFieldsMap = computed(
    () => new Map(orgCustomFields.value.map((field) => [field.id, field]))
  );
  const lastTagQuery = computed(() => store.getters["sims/lastTagQuery"]);

  const updateTagList = (tagsList) => {
    store.commit("sims/addTags", tagsList);
  };
  const saveLastTagQuery = (tagQuery) => store.commit("sims/saveLastTagQuery", tagQuery);

  const fetchActualOrgTagsForSims = (...args) =>
    store.dispatch("sims/fetchActualOrgTagsForSims", ...args);
  const fetchOrgTagsForSims = (...args) => store.dispatch("sims/fetchOrgTagsForSims", ...args);
  const fetchOrgCustomFields = (...args) => store.dispatch("sims/fetchOrgCustomFields", ...args);
  const patchCustomField = (...args) => store.dispatch("sims/patchCustomField", ...args);
  const deleteCustomField = (...args) => store.dispatch("sims/deleteCustomField", ...args);
  const createCustomField = (...args) => store.dispatch("sims/createCustomField", ...args);

  return {
    fetchActualOrgTagsForSims,
    fetchOrgTagsForSims,
    fetchOrgCustomFields,
    updateTagList,
    patchCustomField,
    deleteCustomField,
    createCustomField,
    saveLastTagQuery,
    orgTagsForSims,
    orgCustomFields,
    orgCustomFieldsMap,
    lastTagQuery,
  };
};
