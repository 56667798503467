import { ref, watch, computed } from "vue";
import { simService } from "@/api";
import { useSimStatusApi } from "./use-sim-status-api";
import { useSimsStore } from "./use-sims-store";

const SIM_FIELDS_CONFIG = [
  {
    name: "Name",
    key: "name",
    value: "",
    required: true,
    type: "text",
    frozen: false,
  },
  {
    name: "Description",
    key: "description",
    value: "",
    required: false,
    type: "text",
    frozen: false,
  },
  {
    name: "ICCID",
    key: "iccid",
    value: "",
    required: false,
    type: "text",
    frozen: true,
  },
  {
    name: "Tags",
    key: "tags",
    value: "",
    required: true,
    type: "text",
    frozen: false,
  },
  {
    name: "Operator",
    key: "operator",
    value: "",
    required: false,
    type: "text",
    frozen: false,
  },
  {
    name: "MSISDN",
    key: "msisdn",
    value: "",
    required: false,
    type: "text",
    frozen: false,
  },
  {
    name: "IMSI",
    key: "imsi",
    value: "",
    required: false,
    type: "text",
    frozen: false,
  },
  {
    name: "CHV1",
    key: "chv1",
    value: "",
    required: false,
    type: "password",
    frozen: false,
  },
  {
    name: "CHV2",
    key: "chv2",
    value: "",
    required: false,
    type: "password",
    frozen: false,
  },
  {
    name: "PUK1",
    key: "puk1",
    value: "",
    required: false,
    type: "password",
    frozen: false,
  },
  {
    name: "PUK2",
    key: "puk2",
    value: "",
    required: false,
    type: "password",
    frozen: false,
  },
  {
    name: "Location",
    key: "location",
    value: "",
    required: false,
    type: "text",
    frozen: true,
  },
  {
    name: "Session Information",
    key: "session",
    value: "",
    required: false,
    type: "text",
    frozen: true,
  },
];

const CUSTOM_FIELDS_CONFIG = {
  name: "",
  key: "",
  value: null,
  required: false,
  type: "text",
  frozen: false,
};
export const CustomField = ({ key, value, name }) => ({
  ...CUSTOM_FIELDS_CONFIG,
  key,
  value,
  name,
});

export const CUSTOM_FIELDS_KEY = "customFields";

export const useSimConfiguration = ({ sim, isAdmin }) => {
  const isDirty = ref(false);
  const makeDirty = () => {
    if (!isDirty.value) isDirty.value = true;
  };
  const isSaving = ref(false);
  const isUpdated = ref(false);
  // eslint-disable-next-line no-undef
  const _sim = ref(structuredClone(sim));

  const { changeSimStatus, isChangingStatus, checkDeactivatedSim } = useSimStatusApi();
  const {
    orgCustomFieldsMap,
    patchCustomField,
    deleteCustomField: deleteCustomFieldFronOrgConfig,
  } = useSimsStore();

  const isDeactivatedSim = computed(() => checkDeactivatedSim(_sim.value));

  const _patchSim = ref({});
  const _updateSimValue = (key, value) => {
    _patchSim.value[key] = value;
  };
  const _updateSimCustomFields = (key, value) => {
    const copy = _getCustomFieldsCopy();
    _patchSim.value[CUSTOM_FIELDS_KEY] = { ...copy, [key]: value };
  };

  const _initSimFields = () => {
    return SIM_FIELDS_CONFIG.map((fc) => {
      const value = _sim.value?.[fc.key] || fc.value;
      let frozen = fc.frozen;
      if (fc.key === "name" && !isAdmin.value) {
        frozen = true;
      }
      return { ...fc, value, frozen };
    });
  };

  const _initCustomFields = () => {
    if (!orgCustomFieldsMap.value.size) {
      return [];
    }

    const customFields = [];

    for (const [id, field] of orgCustomFieldsMap.value.entries()) {
      const key = id;
      customFields.push(
        CustomField({ key, value: _sim.value.customFields[key] || "", name: field.name })
      );
    }

    return customFields;
  };

  const simFields = ref(_initSimFields());
  const customFields = ref(_initCustomFields());

  const _getCustomFieldsCopy = () => {
    return Object.fromEntries(customFields.value.map((cf) => [cf.key, cf.value]));
  };

  const requiredFields = computed(() => {
    return simFields.value.filter((f) => f.required);
  });
  const isValid = computed(() => {
    return requiredFields.value.every((f) => {
      if (Array.isArray(f.value)) {
        return f.value.length > 0;
      }
      return f.value.trim().length > 0;
    });
  });

  const _updateFieldInList = (listRef, cb, { key, value, name = "" }) => {
    makeDirty();
    const idx = listRef.value.findIndex((f) => f.key === key);

    if (idx < 0) {
      // it is a new custom field
      listRef.value.push(CustomField({ key, value, name }));
    } else {
      const old = listRef.value[idx];
      listRef.value.splice(idx, 1, { ...old, value });
    }
    cb(key, value);
  };
  const updateSimFieldValue = _updateFieldInList.bind(null, simFields, _updateSimValue);
  const updateCustomFieldValue = _updateFieldInList.bind(
    null,
    customFields,
    _updateSimCustomFields
  );
  const addNewCustomField = _updateFieldInList.bind(null, customFields, _updateSimCustomFields);

  const updateCustomFieldName = (key, newName) => {
    const value = _sim.value[CUSTOM_FIELDS_KEY][key];
    const idx = customFields.value.findIndex((cf) => cf.key === key);
    customFields.value.splice(idx, 1, CustomField({ key, value, name: newName }));
    // we don't need to wait for the API response
    patchCustomField({ id: key, name: newName });
  };
  const deleteCustomField = (key) => {
    makeDirty();
    const copy = _getCustomFieldsCopy();
    delete copy[key];
    // prepare to patch
    _patchSim.value[CUSTOM_FIELDS_KEY] = copy;
    // update in the list
    const idx = customFields.value.findIndex((cf) => cf.key === key);
    customFields.value.splice(idx, 1);
    // we don't need to wait for the API response
    deleteCustomFieldFronOrgConfig({ id: key });
  };

  const save = async () => {
    isSaving.value = true;
    try {
      _patchSim.value.id = _sim.value.id;
      await simService.v2.updateSim(_patchSim.value);
      _patchSim.value = null;
      isUpdated.value = true;
      return true;
    } catch (e) {
      isUpdated.value = false;
      throw e;
    } finally {
      isSaving.value = false;
      isDirty.value = false;
    }
  };

  const updateSimStatus = async () => {
    try {
      const newSim = await changeSimStatus(_sim.value);
      _sim.value = newSim;
      isUpdated.value = true;
      return true;
    } catch (e) {
      isUpdated.value = false;
      // the sim cannot considered updated if the status change failed
      console.error(e);
      throw e;
    }
  };

  const NewSim = () => {
    // eslint-disable-next-line no-undef
    const clone = structuredClone(_sim.value);
    const customFields = _getCustomFieldsCopy();
    const newSimFields = Object.fromEntries(simFields.value.map((f) => [f.key, f.value]));
    return { ...clone, ...newSimFields, customFields };
  };

  const watchToUnfreezeName = (isAdmin) => {
    const unwatch = watch(isAdmin, () => {
      const idx = simFields.value.findIndex((f) => f.key === "name");
      simFields.value[idx].frozen = !isAdmin.value;
      unwatch();
    });
    return unwatch;
  };

  return {
    isDirty,
    simFields,
    customFields,
    updateSimFieldValue,
    updateCustomFieldValue,
    updateCustomFieldName,
    addNewCustomField,
    deleteCustomField,
    watchToUnfreezeName,
    save,
    isSaving,
    isValid,
    isUpdated,
    NewSim,
    sim: _sim,
    isDeactivatedSim,
    updateSimStatus,
    isChangingStatus,
  };
};
